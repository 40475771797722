export const athletesData = [
  {
    athlete: "KOGO, Micah",
    country: "KEN",
    sex: "Men",
    event: "10000m",
    medal: "Bronze",
  },
  {
    athlete: "BEKELE, Kenenisa",
    country: "ETH",
    sex: "Men",
    event: "10000m",
    medal: "Gold",
  },
  {
    athlete: "SIHINE, Sileshi",
    country: "ETH",
    sex: "Men",
    event: "10000m",
    medal: "Silver",
  },
  {
    athlete: "FLANAGAN, Shalane",
    country: "USA",
    sex: "Women",
    event: "10000m",
    medal: "Bronze",
  },
  {
    athlete: "DIBABA, Tirunesh",
    country: "ETH",
    sex: "Women",
    event: "10000m",
    medal: "Gold",
  },
  {
    athlete: "ABEYLEGESSE, Elvan",
    country: "TUR",
    sex: "Women",
    event: "10000m",
    medal: "Silver",
  },
  {
    athlete: "DIX, Walter",
    country: "USA",
    sex: "Men",
    event: "100m",
    medal: "Bronze",
  },
  {
    athlete: "BOLT, Usain",
    country: "JAM",
    sex: "Men",
    event: "100m",
    medal: "Gold",
  },
  {
    athlete: "THOMPSON, Richard",
    country: "TRI",
    sex: "Men",
    event: "100m",
    medal: "Silver",
  },
  {
    athlete: "FRASER, Shelly-ann",
    country: "JAM",
    sex: "Women",
    event: "100m",
    medal: "Gold",
  },
  {
    athlete: "SIMPSON, Sherone",
    country: "JAM",
    sex: "Women",
    event: "100m",
    medal: "Silver",
  },
  {
    athlete: "STEWART, Kerron",
    country: "JAM",
    sex: "Women",
    event: "100m",
    medal: "Silver",
  },
  {
    athlete: "LOPES-SCHLIEP, Priscilla",
    country: "CAN",
    sex: "Women",
    event: "100m hurdles",
    medal: "Bronze",
  },
  {
    athlete: "HARPER, Dawn",
    country: "USA",
    sex: "Women",
    event: "100m hurdles",
    medal: "Gold",
  },
  {
    athlete: "MCLELLAN, Sally",
    country: "AUS",
    sex: "Women",
    event: "100m hurdles",
    medal: "Silver",
  },
  {
    athlete: "OLIVER, David",
    country: "USA",
    sex: "Men",
    event: "110m hurdles",
    medal: "Bronze",
  },
  {
    athlete: "ROBLES, Dayron",
    country: "CUB",
    sex: "Men",
    event: "110m hurdles",
    medal: "Gold",
  },
  {
    athlete: "PAYNE, David",
    country: "USA",
    sex: "Men",
    event: "110m hurdles",
    medal: "Silver",
  },
  {
    athlete: "BAALA, Mehdi",
    country: "FRA",
    sex: "Men",
    event: "1500m",
    medal: "Bronze",
  },
  {
    athlete: "KIPROP, Asbel Kipruto",
    country: "KEN",
    sex: "Men",
    event: "1500m",
    medal: "Gold",
  },
  {
    athlete: "WILLIS, Nicholas",
    country: "NZL",
    sex: "Men",
    event: "1500m",
    medal: "Silver",
  },
  {
    athlete: "TOBIAS, Nataliya",
    country: "UKR",
    sex: "Women",
    event: "1500m",
    medal: "Bronze",
  },
  {
    athlete: "LANGAT, Nancy jebet",
    country: "KEN",
    sex: "Women",
    event: "1500m",
    medal: "Gold",
  },
  {
    athlete: "LISHCHYNSKA, Iryna",
    country: "UKR",
    sex: "Women",
    event: "1500m",
    medal: "Silver",
  },
  {
    athlete: "DIX, Walter",
    country: "USA",
    sex: "Men",
    event: "200m",
    medal: "Bronze",
  },
  {
    athlete: "BOLT, Usain",
    country: "JAM",
    sex: "Men",
    event: "200m",
    medal: "Gold",
  },
  {
    athlete: "CRAWFORD, Shawn",
    country: "USA",
    sex: "Men",
    event: "200m",
    medal: "Silver",
  },
  {
    athlete: "STEWART, Kerron",
    country: "JAM",
    sex: "Women",
    event: "200m",
    medal: "Bronze",
  },
  {
    athlete: "CAMPBELL-BROWN, Veronica",
    country: "JAM",
    sex: "Women",
    event: "200m",
    medal: "Gold",
  },
  {
    athlete: "FELIX, Allyson",
    country: "USA",
    sex: "Women",
    event: "200m",
    medal: "Silver",
  },
  {
    athlete: "RIGAUDO, Elisa",
    country: "ITA",
    sex: "Women",
    event: "20km race walk",
    medal: "Bronze",
  },
  {
    athlete: "KANISKINA, Olga",
    country: "RUS",
    sex: "Women",
    event: "20km race walk",
    medal: "Gold",
  },
  {
    athlete: "PLATZER, Kjersti Tysse",
    country: "NOR",
    sex: "Women",
    event: "20km race walk",
    medal: "Silver",
  },
  {
    athlete: "TALLENT, Jared",
    country: "AUS",
    sex: "Men",
    event: "20km walk",
    medal: "Bronze",
  },
  {
    athlete: "BORCHIN, Valeriy",
    country: "RUS",
    sex: "Men",
    event: "20km walk",
    medal: "Gold",
  },
  {
    athlete: "PEREZ, Jefferson",
    country: "ECU",
    sex: "Men",
    event: "20km walk",
    medal: "Silver",
  },
  {
    athlete: "MATEELONG, Richard Kipkemboi",
    country: "KEN",
    sex: "Men",
    event: "3000m steeplechase",
    medal: "Bronze",
  },
  {
    athlete: "KIPRUTO, Brimin Kiprop",
    country: "KEN",
    sex: "Men",
    event: "3000m steeplechase",
    medal: "Gold",
  },
  {
    athlete: "MEKHISSI-B., Mahiedine",
    country: "FRA",
    sex: "Men",
    event: "3000m steeplechase",
    medal: "Silver",
  },
  {
    athlete: "VOLKOVA, Ekaterina",
    country: "RUS",
    sex: "Women",
    event: "3000m steeplechase",
    medal: "Bronze",
  },
  {
    athlete: "GALKINA-SAMITOVA, Gulnara",
    country: "RUS",
    sex: "Women",
    event: "3000m steeplechase",
    medal: "Gold",
  },
  {
    athlete: "JEPKORIR, Eunice",
    country: "KEN",
    sex: "Women",
    event: "3000m steeplechase",
    medal: "Silver",
  },
  {
    athlete: "NEVILLE, David",
    country: "USA",
    sex: "Men",
    event: "400m",
    medal: "Bronze",
  },
  {
    athlete: "MERRITT, LaShawn",
    country: "USA",
    sex: "Men",
    event: "400m",
    medal: "Gold",
  },
  {
    athlete: "WARINER, Jeremy",
    country: "USA",
    sex: "Men",
    event: "400m",
    medal: "Silver",
  },
  {
    athlete: "RICHARDS, Sanya",
    country: "USA",
    sex: "Women",
    event: "400m",
    medal: "Bronze",
  },
  {
    athlete: "OHURUOGU, Christine",
    country: "GBR",
    sex: "Women",
    event: "400m",
    medal: "Gold",
  },
  {
    athlete: "WILLIAMS, Shericka",
    country: "JAM",
    sex: "Women",
    event: "400m",
    medal: "Silver",
  },
  {
    athlete: "JACKSON, Bershawn",
    country: "USA",
    sex: "Men",
    event: "400m hurdles",
    medal: "Bronze",
  },
  {
    athlete: "TAYLOR, Angelo",
    country: "USA",
    sex: "Men",
    event: "400m hurdles",
    medal: "Gold",
  },
  {
    athlete: "CLEMENT, Kerron",
    country: "USA",
    sex: "Men",
    event: "400m hurdles",
    medal: "Silver",
  },
  {
    athlete: "DANVERS, Tasha",
    country: "GBR",
    sex: "Women",
    event: "400m hurdles",
    medal: "Bronze",
  },
  {
    athlete: "WALKER, Melaine",
    country: "JAM",
    sex: "Women",
    event: "400m hurdles",
    medal: "Gold",
  },
  {
    athlete: "TOSTA, Sheena",
    country: "USA",
    sex: "Women",
    event: "400m hurdles",
    medal: "Silver",
  },
  {
    athlete: "ASAHARA, Nobuharu",
    country: "JPN",
    sex: "Men",
    event: "4x100m relay",
    medal: "Bronze",
  },
  {
    athlete: "SUETSUGU, Shingo",
    country: "JPN",
    sex: "Men",
    event: "4x100m relay",
    medal: "Bronze",
  },
  {
    athlete: "TAKAHIRA, Shinji",
    country: "JPN",
    sex: "Men",
    event: "4x100m relay",
    medal: "Bronze",
  },
  {
    athlete: "TSUKAHARA, Naoki",
    country: "JPN",
    sex: "Men",
    event: "4x100m relay",
    medal: "Bronze",
  },
  {
    athlete: "BOLT, Usain",
    country: "JAM",
    sex: "Men",
    event: "4x100m relay",
    medal: "Gold",
  },
  {
    athlete: "CARTER, Nesta",
    country: "JAM",
    sex: "Men",
    event: "4x100m relay",
    medal: "Gold",
  },
  {
    athlete: "FRATER, Michael",
    country: "JAM",
    sex: "Men",
    event: "4x100m relay",
    medal: "Gold",
  },
  {
    athlete: "POWELL, Asafa",
    country: "JAM",
    sex: "Men",
    event: "4x100m relay",
    medal: "Gold",
  },
  {
    athlete: "BLEDMAN, Keston",
    country: "TRI",
    sex: "Men",
    event: "4x100m relay",
    medal: "Silver",
  },
  {
    athlete: "BURNS, Marc",
    country: "TRI",
    sex: "Men",
    event: "4x100m relay",
    medal: "Silver",
  },
  {
    athlete: "CALLENDER, Emmanuel",
    country: "TRI",
    sex: "Men",
    event: "4x100m relay",
    medal: "Silver",
  },
  {
    athlete: "THOMPSON, Richard",
    country: "TRI",
    sex: "Men",
    event: "4x100m relay",
    medal: "Silver",
  },
  {
    athlete: "IDOKO, Franca",
    country: "NGR",
    sex: "Women",
    event: "4x100m relay",
    medal: "Bronze",
  },
  {
    athlete: "ISMAILA, Halimat",
    country: "NGR",
    sex: "Women",
    event: "4x100m relay",
    medal: "Bronze",
  },
  {
    athlete: "KEMASUODE, Gloria",
    country: "NGR",
    sex: "Women",
    event: "4x100m relay",
    medal: "Bronze",
  },
  {
    athlete: "OSAYOMI, Oludamola",
    country: "NGR",
    sex: "Women",
    event: "4x100m relay",
    medal: "Bronze",
  },
  {
    athlete: "CHERMOSHANSKAYA, Yuliya",
    country: "RUS",
    sex: "Women",
    event: "4x100m relay",
    medal: "Gold",
  },
  {
    athlete: "FEDORIVA, Aleksandra",
    country: "RUS",
    sex: "Women",
    event: "4x100m relay",
    medal: "Gold",
  },
  {
    athlete: "GUSHCHINA, Yulia",
    country: "RUS",
    sex: "Women",
    event: "4x100m relay",
    medal: "Gold",
  },
  {
    athlete: "POLYAKOVA, Evgeniya",
    country: "RUS",
    sex: "Women",
    event: "4x100m relay",
    medal: "Gold",
  },
  {
    athlete: "BORLEE, Olivia",
    country: "BEL",
    sex: "Women",
    event: "4x100m relay",
    medal: "Silver",
  },
  {
    athlete: "GEVAERT, Kim",
    country: "BEL",
    sex: "Women",
    event: "4x100m relay",
    medal: "Silver",
  },
  {
    athlete: "MARIEN, Hanna",
    country: "BEL",
    sex: "Women",
    event: "4x100m relay",
    medal: "Silver",
  },
  {
    athlete: "OUEDRAOGO, Elodie",
    country: "BEL",
    sex: "Women",
    event: "4x100m relay",
    medal: "Silver",
  },
  {
    athlete: "ALEXEEV, Denis",
    country: "RUS",
    sex: "Men",
    event: "4x400m relay",
    medal: "Bronze",
  },
  {
    athlete: "DYLDIN, Maksim",
    country: "RUS",
    sex: "Men",
    event: "4x400m relay",
    medal: "Bronze",
  },
  {
    athlete: "FROLOV, Vladislav",
    country: "RUS",
    sex: "Men",
    event: "4x400m relay",
    medal: "Bronze",
  },
  {
    athlete: "KOKORIN, Anton",
    country: "RUS",
    sex: "Men",
    event: "4x400m relay",
    medal: "Bronze",
  },
  {
    athlete: "MERRITT, LaShawn",
    country: "USA",
    sex: "Men",
    event: "4x400m relay",
    medal: "Gold",
  },
  {
    athlete: "NEVILLE, David",
    country: "USA",
    sex: "Men",
    event: "4x400m relay",
    medal: "Gold",
  },
  {
    athlete: "TAYLOR, Angelo",
    country: "USA",
    sex: "Men",
    event: "4x400m relay",
    medal: "Gold",
  },
  {
    athlete: "WARINER, Jeremy",
    country: "USA",
    sex: "Men",
    event: "4x400m relay",
    medal: "Gold",
  },
  {
    athlete: "BAIN, Andretti",
    country: "BAH",
    sex: "Men",
    event: "4x400m relay",
    medal: "Silver",
  },
  {
    athlete: "BROWN, Christopher",
    country: "BAH",
    sex: "Men",
    event: "4x400m relay",
    medal: "Silver",
  },
  {
    athlete: "MATHIEU, Michael",
    country: "BAH",
    sex: "Men",
    event: "4x400m relay",
    medal: "Silver",
  },
  {
    athlete: "WILLIAMS, Andrae",
    country: "BAH",
    sex: "Men",
    event: "4x400m relay",
    medal: "Silver",
  },
  {
    athlete: "LLOYD, Shereefa",
    country: "JAM",
    sex: "Women",
    event: "4x400m relay",
    medal: "Bronze",
  },
  {
    athlete: "WHYTE, Rosemarie",
    country: "JAM",
    sex: "Women",
    event: "4x400m relay",
    medal: "Bronze",
  },
  {
    athlete: "WILLIAMS, Novelene",
    country: "JAM",
    sex: "Women",
    event: "4x400m relay",
    medal: "Bronze",
  },
  {
    athlete: "WILLIAMS, Shericka",
    country: "JAM",
    sex: "Women",
    event: "4x400m relay",
    medal: "Bronze",
  },
  {
    athlete: "FELIX, Allyson",
    country: "USA",
    sex: "Women",
    event: "4x400m relay",
    medal: "Gold",
  },
  {
    athlete: "HENDERSON, Monique",
    country: "USA",
    sex: "Women",
    event: "4x400m relay",
    medal: "Gold",
  },
  {
    athlete: "RICHARDS, Sanya",
    country: "USA",
    sex: "Women",
    event: "4x400m relay",
    medal: "Gold",
  },
  {
    athlete: "WINEBERG, Mary",
    country: "USA",
    sex: "Women",
    event: "4x400m relay",
    medal: "Gold",
  },
  {
    athlete: "FIROVA, Tatiana",
    country: "RUS",
    sex: "Women",
    event: "4x400m relay",
    medal: "Silver",
  },
  {
    athlete: "GUSHCHINA, Yulia",
    country: "RUS",
    sex: "Women",
    event: "4x400m relay",
    medal: "Silver",
  },
  {
    athlete: "KAPACHINSKAYA, Anastasia",
    country: "RUS",
    sex: "Women",
    event: "4x400m relay",
    medal: "Silver",
  },
  {
    athlete: "LITVINOVA, Liudmila",
    country: "RUS",
    sex: "Women",
    event: "4x400m relay",
    medal: "Silver",
  },
  {
    athlete: "SOI, Edwin Cheruiyot",
    country: "KEN",
    sex: "Men",
    event: "5000m",
    medal: "Bronze",
  },
  {
    athlete: "BEKELE, Kenenisa",
    country: "ETH",
    sex: "Men",
    event: "5000m",
    medal: "Gold",
  },
  {
    athlete: "KIPCHOGE, Eliud",
    country: "KEN",
    sex: "Men",
    event: "5000m",
    medal: "Silver",
  },
  {
    athlete: "DEFAR, Meseret",
    country: "ETH",
    sex: "Women",
    event: "5000m",
    medal: "Bronze",
  },
  {
    athlete: "DIBABA, Tirunesh",
    country: "ETH",
    sex: "Women",
    event: "5000m",
    medal: "Gold",
  },
  {
    athlete: "ABEYLEGESSE, Elvan",
    country: "TUR",
    sex: "Women",
    event: "5000m",
    medal: "Silver",
  },
  {
    athlete: "NIZHEGORODOV, Denis",
    country: "RUS",
    sex: "Men",
    event: "50km walk",
    medal: "Bronze",
  },
  {
    athlete: "SCHWAZER, Alex",
    country: "ITA",
    sex: "Men",
    event: "50km walk",
    medal: "Gold",
  },
  {
    athlete: "TALLENT, Jared",
    country: "AUS",
    sex: "Men",
    event: "50km walk",
    medal: "Silver",
  },
  {
    athlete: "YEGO, Alfred Kirwa",
    country: "KEN",
    sex: "Men",
    event: "800m",
    medal: "Bronze",
  },
  {
    athlete: "BUNGEI, Wilfred",
    country: "KEN",
    sex: "Men",
    event: "800m",
    medal: "Gold",
  },
  {
    athlete: "ISMAIL, Ismail Ahmed",
    country: "SUD",
    sex: "Men",
    event: "800m",
    medal: "Silver",
  },
  {
    athlete: "BENHASSI, Hasna",
    country: "MAR",
    sex: "Women",
    event: "800m",
    medal: "Bronze",
  },
  {
    athlete: "JELIMO, Pamela",
    country: "KEN",
    sex: "Women",
    event: "800m",
    medal: "Gold",
  },
  {
    athlete: "BUSIENEI, Janeth Jepkosgei",
    country: "KEN",
    sex: "Women",
    event: "800m",
    medal: "Silver",
  },
  {
    athlete: "SUAREZ, Leonel",
    country: "CUB",
    sex: "Men",
    event: "decathlon",
    medal: "Bronze",
  },
  {
    athlete: "CLAY, Bryan",
    country: "USA",
    sex: "Men",
    event: "decathlon",
    medal: "Gold",
  },
  {
    athlete: "KRAUCHANKA, Andrei",
    country: "BLR",
    sex: "Men",
    event: "decathlon",
    medal: "Silver",
  },
  {
    athlete: "ALEKNA, Virgilijus",
    country: "LTU",
    sex: "Men",
    event: "discus throw",
    medal: "Bronze",
  },
  {
    athlete: "KANTER, Gerd",
    country: "EST",
    sex: "Men",
    event: "discus throw",
    medal: "Gold",
  },
  {
    athlete: "MALACHOWSKI, Piotr",
    country: "POL",
    sex: "Men",
    event: "discus throw",
    medal: "Silver",
  },
  {
    athlete: "ANTONOVA, Olena",
    country: "UKR",
    sex: "Women",
    event: "discus throw",
    medal: "Bronze",
  },
  {
    athlete: "BROWN TRAFTON, Stephanie",
    country: "USA",
    sex: "Women",
    event: "discus throw",
    medal: "Gold",
  },
  {
    athlete: "BARRIOS, Yarelys",
    country: "CUB",
    sex: "Women",
    event: "discus throw",
    medal: "Silver",
  },
  {
    athlete: "TSIKHAN, Ivan",
    country: "BLR",
    sex: "Men",
    event: "hammer throw",
    medal: "Bronze",
  },
  {
    athlete: "KOZMUS, Primoz",
    country: "SLO",
    sex: "Men",
    event: "hammer throw",
    medal: "Gold",
  },
  {
    athlete: "DEVYATOVSKIY, Vadim",
    country: "BLR",
    sex: "Men",
    event: "hammer throw",
    medal: "Silver",
  },
  {
    athlete: "ZHANG, Wenxiu",
    country: "CHN",
    sex: "Women",
    event: "hammer throw",
    medal: "Bronze",
  },
  {
    athlete: "MIANKOVA, Aksana",
    country: "BLR",
    sex: "Women",
    event: "hammer throw",
    medal: "Gold",
  },
  {
    athlete: "MORENO, Yipsi",
    country: "CUB",
    sex: "Women",
    event: "hammer throw",
    medal: "Silver",
  },
  {
    athlete: "CHERNOVA, Tatiana",
    country: "RUS",
    sex: "Women",
    event: "heptathlon",
    medal: "Bronze",
  },
  {
    athlete: "DOBRYNSKA, Nataliia",
    country: "UKR",
    sex: "Women",
    event: "heptathlon",
    medal: "Gold",
  },
  {
    athlete: "FOUNTAIN, Hyleas",
    country: "USA",
    sex: "Women",
    event: "heptathlon",
    medal: "Silver",
  },
  {
    athlete: "RYBAKOV, Yaroslav",
    country: "RUS",
    sex: "Men",
    event: "high jump",
    medal: "Bronze",
  },
  {
    athlete: "SILNOV, Andrey",
    country: "RUS",
    sex: "Men",
    event: "high jump",
    medal: "Gold",
  },
  {
    athlete: "MASON, Germaine",
    country: "GBR",
    sex: "Men",
    event: "high jump",
    medal: "Silver",
  },
  {
    athlete: "CHICHEROVA, Anna",
    country: "RUS",
    sex: "Women",
    event: "high jump",
    medal: "Bronze",
  },
  {
    athlete: "HELLEBAUT, Tia",
    country: "BEL",
    sex: "Women",
    event: "high jump",
    medal: "Gold",
  },
  {
    athlete: "VLASIC, Blanka",
    country: "CRO",
    sex: "Women",
    event: "high jump",
    medal: "Silver",
  },
  {
    athlete: "PITKAMAKI, Tero",
    country: "FIN",
    sex: "Men",
    event: "javelin throw",
    medal: "Bronze",
  },
  {
    athlete: "THORKILDSEN, Andreas",
    country: "NOR",
    sex: "Men",
    event: "javelin throw",
    medal: "Gold",
  },
  {
    athlete: "KOVALS, Ainars",
    country: "LAT",
    sex: "Men",
    event: "javelin throw",
    medal: "Silver",
  },
  {
    athlete: "OBERGFOLL, Christina",
    country: "GER",
    sex: "Women",
    event: "javelin throw",
    medal: "Bronze",
  },
  {
    athlete: "SPOTAKOVA, Barbora",
    country: "CZE",
    sex: "Women",
    event: "javelin throw",
    medal: "Gold",
  },
  {
    athlete: "ABAKUMOVA, Maria",
    country: "RUS",
    sex: "Women",
    event: "javelin throw",
    medal: "Silver",
  },
  {
    athlete: "CAMEJO, Ibrahim",
    country: "CUB",
    sex: "Men",
    event: "long jump",
    medal: "Bronze",
  },
  {
    athlete: "SALADINO ARANDA, Irving Jahir",
    country: "PAN",
    sex: "Men",
    event: "long jump",
    medal: "Gold",
  },
  {
    athlete: "MOKOENA, Khotso",
    country: "RSA",
    sex: "Men",
    event: "long jump",
    medal: "Silver",
  },
  {
    athlete: "OKAGBARE, Blessing",
    country: "NGR",
    sex: "Women",
    event: "long jump",
    medal: "Bronze",
  },
  {
    athlete: "MAGGI, Maurren Higa",
    country: "BRA",
    sex: "Women",
    event: "long jump",
    medal: "Gold",
  },
  {
    athlete: "LEBEDEVA, Tatyana",
    country: "RUS",
    sex: "Women",
    event: "long jump",
    medal: "Silver",
  },
  {
    athlete: "KEBEDE, Tsegay",
    country: "ETH",
    sex: "Men",
    event: "marathon",
    medal: "Bronze",
  },
  {
    athlete: "WANSIRU, Samuel Kamau",
    country: "KEN",
    sex: "Men",
    event: "marathon",
    medal: "Gold",
  },
  {
    athlete: "GHARIB, Jaouad",
    country: "MAR",
    sex: "Men",
    event: "marathon",
    medal: "Silver",
  },
  {
    athlete: "ZHOU, Chunxiu",
    country: "CHN",
    sex: "Women",
    event: "marathon",
    medal: "Bronze",
  },
  {
    athlete: "TOMESCU, Constantina",
    country: "ROU",
    sex: "Women",
    event: "marathon",
    medal: "Gold",
  },
  {
    athlete: "NDEREBA, Catherine",
    country: "KEN",
    sex: "Women",
    event: "marathon",
    medal: "Silver",
  },
  {
    athlete: "YURCHENKO, Denys",
    country: "UKR",
    sex: "Men",
    event: "pole vault",
    medal: "Bronze",
  },
  {
    athlete: "HOOKER, Steve",
    country: "AUS",
    sex: "Men",
    event: "pole vault",
    medal: "Gold",
  },
  {
    athlete: "LUKYANENKO, Evgeny",
    country: "RUS",
    sex: "Men",
    event: "pole vault",
    medal: "Silver",
  },
  {
    athlete: "FEOFANOVA, Svetlana",
    country: "RUS",
    sex: "Women",
    event: "pole vault",
    medal: "Bronze",
  },
  {
    athlete: "ISINBAEVA, Elena",
    country: "RUS",
    sex: "Women",
    event: "pole vault",
    medal: "Gold",
  },
  {
    athlete: "STUCZYNSKI, Jennifer",
    country: "USA",
    sex: "Women",
    event: "pole vault",
    medal: "Silver",
  },
  {
    athlete: "MIKHNEVICH, Andrei",
    country: "BLR",
    sex: "Men",
    event: "shot put",
    medal: "Bronze",
  },
  {
    athlete: "MAJEWSKI, Tomasz",
    country: "POL",
    sex: "Men",
    event: "shot put",
    medal: "Gold",
  },
  {
    athlete: "CANTWELL, Christian",
    country: "USA",
    sex: "Men",
    event: "shot put",
    medal: "Silver",
  },
  {
    athlete: "OSTAPCHUK, Nadzeya",
    country: "BLR",
    sex: "Women",
    event: "shot put",
    medal: "Bronze",
  },
  {
    athlete: "VILI, Valerie",
    country: "NZL",
    sex: "Women",
    event: "shot put",
    medal: "Gold",
  },
  {
    athlete: "MIKHNEVICH, Natallia",
    country: "BLR",
    sex: "Women",
    event: "shot put",
    medal: "Silver",
  },
  {
    athlete: "SANDS, Leevan",
    country: "BAH",
    sex: "Men",
    event: "triple jump",
    medal: "Bronze",
  },
  {
    athlete: "EVORA, Nelson",
    country: "POR",
    sex: "Men",
    event: "triple jump",
    medal: "Gold",
  },
  {
    athlete: "IDOWU, Phillips",
    country: "GBR",
    sex: "Men",
    event: "triple jump",
    medal: "Silver",
  },
  {
    athlete: "DEVETZI, Hrysopiyi",
    country: "GRE",
    sex: "Women",
    event: "triple jump",
    medal: "Bronze",
  },
  {
    athlete: "MBANGO ETONE, Francoise",
    country: "CMR",
    sex: "Women",
    event: "triple jump",
    medal: "Gold",
  },
  {
    athlete: "LEBEDEVA, Tatyana",
    country: "RUS",
    sex: "Women",
    event: "triple jump",
    medal: "Silver",
  },
];
